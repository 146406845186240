
import { defineComponent, reactive, toRefs } from 'vue';
import { PlusOutlined } from '@ant-design/icons-vue';
import { queryFakeList } from '@/api/list/basic-list';
import { TableListItem } from '../typing';
import Info from './info.vue';
import moment from 'moment';
import { useFetchData, RequestParams } from '@/utils/hooks/useFetchData';

export default defineComponent({
  setup() {
    const { reload, context } = useFetchData<{
      data: TableListItem[];
      success: boolean;
      total: number;
    }>(
      (params?: RequestParams) => {
        return new Promise<{
          data: TableListItem[];
          success: boolean;
          total: number;
        }>((resolve, reject) => {
          queryFakeList({ count: params?.pageSize || 10 })
            .then((res: TableListItem[]) => {
              resolve({
                data: res,
                success: true,
                total: 10,
              });
            })
            .catch(reject);
        });
      },
      {
        loading: false,
        pageSize: 5,
      },
    );

    const { total, pageSize } = toRefs(context);

    const options = reactive({
      showSizeChanger: true,
      showQuickJumper: true,
      pageSizeOptions: ['5', '10', '15', '20'],
      pageSize,
      total,
      onChange: (page: number, pageSize: number) => {
        context.current = page;
        context.pageSize = pageSize;
        reload();
      },
      onShowSizeChange: (current: number, size: number) => {
        context.pageSize = size;
        reload();
      },
    });

    return {
      moment,

      options,
      context,
    };
  },
  components: {
    Info,
    PlusOutlined,
  },
});
